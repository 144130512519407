<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0"
    >
      <div class="form__section-title">
        Szczegóły płatności
      </div>
    </v-col>

    <v-col
      cols="8"
      class="py-0"
    >
      <PaymentType
        :payment-data="paymentData"
        cols="7/5"
        @update="$emit('update',$event)"
      />
    </v-col>

    <v-col cols="4">
      <v-autocomplete
        outlined
        :items="options.settlementTypes"
        :rules="[rules.required]"
        label="Typ dokumentu"
        :value="paymentData.settlementType"
        @input="emitUpdate('payment.settlementType', $event)"
        placeholder="Wybierz typ dokumentu"
        append-icon="mdi-chevron-down"
        dense
      />
    </v-col>

    <v-col
      v-if="!isTransportIncluded"
      cols="4"
    >
      <v-text-field
        outlined
        label="Wysokość rabatu [zł]"
        :value="paymentData.discount"
        type="number"
        validate-on-blur
        :rules="[rules.money, rules.lowerThan(10000001)]"
        @input="emitUpdate('payment.discount', $event)"
        placeholder="0zł"
      />
    </v-col>

    <v-col
      v-if="!isTransportIncluded"
      cols="8"
    >
      <v-text-field
        outlined
        label="Uzasadnienie rabatu"
        :value="paymentData.discountJustification"
        @input="emitUpdate('payment.discountJustification', $event)"
        placeholder="Wpisz uzasadnienie rabatu"
      />
    </v-col>

    <v-col
      cols="12"
      class="py-0"
    >
      <PaymentRow
        :net-amount="paymentData.debrisNetValue"
        :label="debrisGenitive"
        :tax-value="paymentData.vatPercentage"
        @update="updatePayment('debris',$event)"
      />
    </v-col>

    <v-col
      cols="12"
      v-if="isTransportIncluded"
      class="py-0"
    >
      <PaymentRow
        :net-amount="paymentData.transportNetValue"
        label="transportu"
        :tax-value="paymentData.vatPercentage"
        @update="updatePayment('transport',$event)"
      />
    </v-col>

    <v-col
      cols="12"
      class="pt-0 d-flex justify-space-between"
    >
      <div class="input-label">
        <div v-if="locationDiscount">
          Rabat lokalizacji klienta: {{ locationDiscount }} zł
        </div>
        <div v-if="suggestedTransportPricePLN">
          Sugerowana cena transportu: {{ suggestedTransportPricePLN }} zł
        </div>
      </div>
      <div class="input-label mr-2">
        Do zapłaty: {{ amountLeftToPay }} zł
      </div>
    </v-col>
  </v-row>
</template>

<script>
import rules from '../../../utils/validators'
import PaymentRow from './PaymentRow'
import PaymentType from './PaymentType'
import { parseFloatNumber, filterTransportPaymentTypes, filterDebrisPaymentTypes, getGrossValue } from '../../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    PaymentRow,
    PaymentType,
  },
  props: {
    paymentData: {
      type: Object,
      required: true
    },
    courseType: {
      type: String,
      default: ''
    },
    locationDiscount: {
      type: Number,
      default: null
    },
    distance: {
      type: Number,
      default: null
    },
    aggregateAmount: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      rules
    }
  },
  computed: {
    ...mapState({
      options: state => state.core.filters,
      department: state => state.core.department,
      transportPrices: state => {
        return [...state.transportPrices.items].sort((a, b) => a.maximumDistance - b.maximumDistance)
      }
    }),
    suggestedTransportPricePLN () {
      return this.distance && this.transportPrices?.find(price => price.maximumDistance / 1000 > this.distance)?.price / 100
    },
    isTransportIncluded () {
      return ['Odbiór surowca', 'Transport'].includes(this.courseType)
    },
    debrisGenitive () {
      if (!this.isTransportIncluded) return ''
      return this.courseType === 'Transport' ? 'kruszywa za tonę' : 'surowca'
    },
    taxValue () {
      const tax = this.isTransportIncluded ? 23 : 8
      this.emitUpdate('payment.vatPercentage', tax)
      return tax
    },
    amountLeftToPay () {
      const { discount, debrisNetValue, transportNetValue } = this.paymentData
      const debrisValue = this.courseType === 'Transport' ? parseFloatNumber(debrisNetValue) * (+this.aggregateAmount || 1) : parseFloatNumber(debrisNetValue)
      const value = this.getGrossValue(debrisValue + parseFloatNumber(transportNetValue) - parseFloatNumber(discount))
      return value > 0 ? value.toFixed(2) : 0
    },
    paymentTypes () {
      const { paymentTypes } = this.options
      return this.courseType === 'Transport'
        ? filterTransportPaymentTypes(paymentTypes)
        : filterDebrisPaymentTypes(paymentTypes)
    },
  },
  watch: {
    courseType: {
      immediate: true,
      handler (type) {
        if (!this.paymentTypes.includes(this.paymentData.paymentType)) {
          if (type === 'Transport') {
            this.$nextTick(() => {
              this.emitUpdate('payment.paymentType', 'Gotówka')
            })
            if (this.suggestedTransportPricePLN) {
              this.emitUpdate('payment.transportNetValue', this.suggestedTransportPricePLN)
              this.emitUpdate('payment.transportGrossValue', this.getGrossValue(this.suggestedTransportPricePLN))
            }
          } else {
            this.$nextTick(() => {
              this.emitUpdate('payment.paymentType', 'Gotówka przy podstawieniu')
            })
          }
        }
      }
    },
    suggestedTransportPricePLN (price) {
      if (this.courseType === 'Transport') {
        this.emitUpdate('payment.transportNetValue', price)
        this.emitUpdate('payment.transportGrossValue', this.getGrossValue(price))
      }
    }
  },
  mounted () {
    this.getTransportPrices({ departentId: this.department.id })
  },
  methods: {
    ...mapActions({
      getTransportPrices: 'transportPrices/getItems'
    }),
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
    updatePayment (type, values) {
      if (type === 'debris') {
        this.emitUpdate('payment.debrisNetValue', values.netValue)
        this.emitUpdate('payment.debrisGrossValue', values.grossValue)
      } else {
        this.emitUpdate('payment.transportNetValue', values.netValue)
        this.emitUpdate('payment.transportGrossValue', values.grossValue)
      }
    },
    getGrossValue (value) {
      return getGrossValue(value, this.taxValue)
    }
  }
}
</script>
