<script>
import AddressForm from '../components/Forms/Partials/AddressForm'
import NewCoursePartial from '../components/Forms/Partials/NewCourse'
import NewPaymentPartial from '../components/Forms/Partials/NewPayment'
import DialogFormWrapper from '../components/Forms/Partials/DialogFormWrapper'
import ChooseClientAddressPartial from '../components/Forms//Partials/ChooseClientAddress'
import { getDistanceBetweenTwoPoints } from '../utils'
import { NewOrder } from '../models'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    AddressForm,
    NewCoursePartial,
    DialogFormWrapper,
    NewPaymentPartial,
    ChooseClientAddressPartial
  },
  data: () => ({
    ...new NewOrder(), // order, course, payment
    showAddressForm: false,
    chosenAddress: undefined,
    locationDiscount: null,
    destinationCoordinates: null
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      isProcessing: state => state.orders.isProcessing,
      addresses: state => state.addresses.items,
    }),
    straightLineDistance () {
      const baseCoordinates = { baseLat: this.department.lat, baseLng: this.department.lng }
      return this.destinationCoordinates ? getDistanceBetweenTwoPoints(baseCoordinates, this.destinationCoordinates) : 0
    }
  },
  watch: {
    'order.addressId' (id) {
      this.populateAddressData(id)
    },
  },
  methods: {
    ...mapActions({
      addNewAddress: 'addresses/addNewItem',
      editAddress: 'addresses/editItem',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar',
      getAddresses: 'addresses/getItems',
    }),
    changeAddressFormStatus (status, isUpdating) {
      this.showAddressForm = status
      this.chosenAddress = isUpdating && this.addresses.find(address => address.id === this.order.addressId)
    },
    fetchClientAddresses (setAddress = false) {
      return this.getAddresses({
        params: { departmentId: this.department.id, clientId: this.order.clientId }
      }).then(() => {
        if (setAddress) this.order.addressId = this.addresses?.[0]?.id
      })
    },
    addAddress (address) {
      const params = {
        ...address,
        clientId: this.order.clientId
      }
      this.addNewAddress({ params })
        .then((resp) => {
          this.fetchClientAddresses()
          this.order.addressId = resp.id
          this.showAddressForm = false
        })
    },
    updateAddress (address) {
      const params = {
        tableName: 'addresses',
        params: { ...address },
        id: this.order.addressId
      }
      this.editAddress(params)
        .then(() => {
          this.fetchClientAddresses()
          this.showAddressForm = false
        })
    },
    populateAddressData (id) {
      const address = this.addresses.find(address => address.id === id)
      this.destinationCoordinates = address ? { destLat: address.location.lat, destLng: address.location.lng } : null
      if (this.updatePayment) {
        this.payment.discountJustification = address ? address.discountJustification : null
        this.payment.discount = address ? address.discount / 100 : null // editable
        this.locationDiscount = address ? address.discount / 100 : null // not editable
      }
    },
  }
}
</script>
